<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">数据同步</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  <!--  详细内容-->
    <div>
      <!--Cookie-->
      <div style="width: 90%;margin: 0 auto">
        <el-input
            v-model="cookie"
            autosize
            type="textarea"
            placeholder="请输入Cookie"
        />
      </div>
    <!--  选择标段-->
      <div style="margin-left: 5%;margin-top: 2%;display: flex;justify-content: space-between">
        <div>
          <el-select v-model="bdst" class="m-2" placeholder="请选择标段" size="large">
            <el-option
                v-for="item in bdsts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
        <!--  提交按钮-->
        <div style="float: right;margin-right: 30%;margin-bottom: 10%">
          <el-button type="success" @click="synrempdata">同步数据</el-button>
          <el-button type="success" @click="synrempmoney">同步产值</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {setNowTimes} from "@/utils/time";
import { ElMessage } from 'element-plus'

export default {
  components: {Header},
  name: "synremp",
  data(){
    return{
      cookie: '',
      bdst: '',
      bdsts: [
        {
          value: '2',
          label: '2标',
        },
        {
          value: '5',
          label: '5标',
        }
      ]
    }
  },
  methods:{
    synrempdata(){
      this.axios.post('/synRemp/synRempData', (response) =>  {
        if (response.obj){
          ElMessage({
            message: '同步成功!',
            type: 'success',
          })
        }
      },{
        cookie: this.cookie,
        bdst: this.bdst,
        time: setNowTimes(new Date())
      })
    },
    synrempmoney(){
      this.axios.post('/synRemp/synRempMoney', (response) =>  {
        if (response.obj){
          ElMessage({
            message: '同步成功!',
            type: 'success',
          })
        }
      },{
        cookie: this.cookie
      })
    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
</style>